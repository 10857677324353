import { FallbackProps } from '@/config/hoc/with-fallback';
import { useToken } from '@/config';
import { hasTrainerRole, isNil, MeTrainer, useMeClient } from '@/shared';
import { useLoaderHandler } from '@/shared/utils/loader/use-loader-handler';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getMe, MeTrainerResponse } from '@/api';
import { handleApiError } from '@/shared/utils/helpers/http/handle-api-error';
import { mapMeTrainer } from '@/shared/repository/trainer-mapper';

export const ME_TRAINER_QUERY_KEY = 'me-trainer-query';

export const useMeTrainer = (fallback?: FallbackProps<MeTrainer>) => {
	const { isAuthenticated, user } = useAuth0();
	const { tokenReady } = useToken();
	const { me: client } = useMeClient();

	const { data: me, isLoading } = useQuery({
		queryKey: [ME_TRAINER_QUERY_KEY],
		staleTime: 1000 * 60, // 1 minute,
		enabled: isAuthenticated && tokenReady && !isNil(client) && hasTrainerRole(user),
		queryFn: async () => {
			const rawResponse = await getMe();
			const { data } = handleApiError<MeTrainerResponse>(rawResponse);
			return mapMeTrainer(data);
		}
	});

	const loading = useLoaderHandler(
		[{ loading: isLoading }],
		[me],
		() => fallback?.onError(),
		(data) => fallback?.onSuccess(data[0]!)
	);

	return {
		me,
		loading
	};
};
