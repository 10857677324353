import { FallbackProps } from '@/config/hoc/with-fallback';
import { useNavigate, useToken, useTranslation } from '@/config';
import { isNil, MeClient } from '@/shared';
import { useAuth0, User } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { create1, CreateClientRequest, getMeClient, MeClientResponse } from '@/api';
import { handleApiError } from '@/shared/utils/helpers/http/handle-api-error';
import { mapMeClient } from '@/shared/repository/client-mapper';
import { useEffect } from 'react';
import { useNotification } from '@/ui/notification/notification-context';

export const ME_CLIENT_QUERY_KEY = 'me-client-query';

const createNewClient = async (user?: User) => {
	const defaultAvatarURL = `https://avatar.oxro.io/avatar.svg?name=${
		user?.name ?? ''
	}&background=FFCF00&color=000000&length=2&caps=1`;

	const sex =
		user?.gender?.toLowerCase() === 'male'
			? 'MALE'
			: user?.gender?.toLowerCase() === 'female'
			? 'FEMALE'
			: undefined;
	const req: CreateClientRequest = {
		name: user?.given_name,
		surname: user?.family_name,
		email: user!.email!,
		phoneNumber: user?.phone_number,
		avatarUrl: user?.picture ?? defaultAvatarURL,
		sex: sex
	};
	const rawResponse = await create1({ body: req });
	const { data } = handleApiError<MeClientResponse>(rawResponse);
	return mapMeClient(data);
};

const hasProfileFilled = (client: MeClient) =>
	!isNil(client.contactDetails.name) &&
	!isNil(client.contactDetails.surname) &&
	!isNil(client.contactDetails.phone);

export const useMeClient = (fallback?: FallbackProps<MeClient>) => {
	const { isAuthenticated, user } = useAuth0();
	const { tokenReady } = useToken();
	const navigate = useNavigate();
	const { open } = useNotification();
	const { t } = useTranslation();

	const {
		data: me,
		status,
		isLoading
	} = useQuery({
		queryKey: [ME_CLIENT_QUERY_KEY],
		staleTime: 1000 * 60, // 1 minute,
		retry: false,
		enabled: isAuthenticated && tokenReady && !isNil(user) && !isNil(user.email),
		queryFn: async () => {
			const rawResponse = await getMeClient();
			if (rawResponse.response.status === 404) {
				return createNewClient(user);
			}
			const { data } = handleApiError<MeClientResponse>(rawResponse);
			return mapMeClient(data);
		}
	});

	useEffect(() => {
		if (status === 'error') {
			fallback?.onError();
		} else if (status === 'success') {
			fallback?.onSuccess(me!);
		}
	}, [fallback, me, status]);

	useEffect(() => {
		if (!isNil(me) && !hasProfileFilled(me)) {
			open({
				type: 'warning',
				title: t('shared.profile-lack'),
				content: t('shared.profile-fill-data')
			});
			navigate('me/settings');
		}
	}, [me, navigate, open, t]);

	return {
		me,
		loading: isLoading
	};
};
