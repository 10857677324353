import { Flex } from '@/ui/flex/Flex';
import { primary } from '@/ui/theme/components-overrides/colors';
import { isNil, useMeClient, useMeTrainer } from '@/shared';
import { FlexCard } from '@/ui/flex-card/FlexCard';
import { FallbackProps, withFallback } from '@/config/hoc/with-fallback';
import { FC } from 'react';
import { ClientDetails } from '@/config/layout/navigation/helpers/mobile/ClientDetails';
import { TrainerDetails } from '@/config/layout/navigation/helpers/mobile/TrainerDetails';

const UserDetails: FC<FallbackProps> = ({ ...fallbackProps }) => {
	const { me: meClient } = useMeClient();
	const { me: meTrainer } = useMeTrainer();

	return (
		<FlexCard padding={2} borderRadius={'4px'} overflow={'unset'} bgcolor={primary['25']}>
			<Flex gap={2} flexDirection={'column'} width={'100%'}>
				{!isNil(meClient) && <ClientDetails client={meClient} />}
				{!isNil(meTrainer) && (
					<TrainerDetails
						onError={fallbackProps.onError}
						onSuccess={fallbackProps.onSuccess}
					/>
				)}
			</Flex>
		</FlexCard>
	);
};

export default withFallback(UserDetails);
