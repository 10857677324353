import {
	addAchievement,
	addCashout,
	AddCashoutResponse,
	addCertificate,
	addPackageOfferVariants,
	AddPackageOfferVariantsRequest,
	addStudy,
	deleteAchievement,
	deleteCertificate,
	deleteLocation,
	deleteMe,
	type DeleteMeResponse,
	deletePackageOfferVariants,
	deleteStudy,
	type DeleteTrainerLocationRequest,
	downloadSelfCertificate,
	type DownloadSelfCertificateResponse,
	getBalance,
	getBankAccount,
	getMe,
	GetMeResponse,
	getMyNextTraining,
	getOnBoardingUrl,
	listCalendarOffers,
	ListCalendarOffersResponse,
	listOrders,
	type ListOrdersResponse,
	MeTrainerResponse,
	TrainerBalanceResponse,
	TrainerBankAccountResponse,
	updateAchievement,
	updateCertificate,
	updateMe,
	updateNotifications,
	UpdatePackageOfferVariantsRequest,
	updatePackageTrainingVariants,
	updateStudy,
	UpdateTrainerNotificationSettingsRequest,
	upsertLocation,
	upsertOfferTemplate,
	UpsertOfferTemplateRequest,
	UpsertTrainerAchievementRequest,
	UpsertTrainerLocationRequest,
	type UpsertTrainerStudyRequest,
	UrlDTO
} from '@/api';
import {
	CurrencyCode,
	Discipline,
	DownloadFileResponse,
	isNil,
	isNotNil,
	Maybe,
	MeClient,
	MeTrainer,
	Order,
	Page,
	Sex,
	TrainerBalance,
	TrainerBankAccount,
	TrainerTraining,
	TrainingCategory,
	useStore
} from '@/shared';
import { useCallback } from 'react';
import { combineLatestWith, EMPTY, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { NotificationType } from '@/shared/model/Notification';
import { mapMeTrainer } from '@/shared/repository/trainer-mapper';
import { useAuth0 } from '@auth0/auth0-react';
import { OrderQuery } from '@/shared/repository/trainer/trainer-order-api-types';
import { mapOrder } from '@/shared/repository/order-mapper';
import { mapTrainerTraining } from '@/shared/repository/training-mapper';
import {
	castRequestResult,
	handleApiError,
	mapApiResult
} from '@/shared/utils/helpers/rxjs/handle-api-error';
import { CalendarOffer, CalendarOfferQuery } from '@/shared/model/CalendarOffer';
import { mapCalendarOffer } from '@/shared/repository/calendar-offer-mapper';
import { filterCalendarOffers } from '@/shared/utils/calendar-offers-picker/calendar-daily-offers';
import { mapBalance } from '@/shared/repository/balance-mapper';

export type AddTrainerCertificateRequest = {
	name: string;
	discipline: Discipline;
};

export type UpdateTrainerCertificateRequest = {
	certificateId: string;
	name: string;
	discipline: Discipline;
};

export type UpdateTrainerBasicInformationRequest = {
	name: string;
	surname: string;
	phoneNumber: string;
	description: Maybe<string>;
	sex?: Maybe<Sex>;
	languages: string[];
};

type SelfTrainerFunctions = {
	deleteMe: () => Observable<void>;
	getMe: () => Observable<MeTrainer>;
	getNextTraining: () => Observable<Maybe<TrainerTraining>>;
	getTrainerOrders: (query: OrderQuery) => Observable<Page<Order>>;

	updateMe: (
		avatarImage: Maybe<Blob>,
		data: UpdateTrainerBasicInformationRequest
	) => Observable<MeTrainer>;

	updateNotifications: (enabledNotifications: Array<NotificationType>) => Observable<MeTrainer>;

	// ------------------ STUDY ------------------
	addStudy: (request: UpsertTrainerStudyRequest) => Observable<MeTrainer>;
	updateStudy: (studyId: string, request: UpsertTrainerStudyRequest) => Observable<MeTrainer>;
	deleteStudy: (studyId: string) => Observable<MeTrainer>;

	// ------------------ ACHIEVEMENT ------------------
	addAchievement: (request: UpsertTrainerAchievementRequest) => Observable<MeTrainer>;
	updateAchievement: (
		achievementId: string,
		request: UpsertTrainerAchievementRequest
	) => Observable<MeTrainer>;
	deleteAchievement: (achievementId: string) => Observable<MeTrainer>;

	// ------------------ LOCATIONS ------------------
	upsertLocation: (request: UpsertTrainerLocationRequest) => Observable<MeTrainer>;
	deleteLocation: (request: DeleteTrainerLocationRequest) => Observable<MeTrainer>;

	// ------------------ CERTIFICATES ------------------
	addCertificate: (
		certificateImage: Blob | File,
		certificate: AddTrainerCertificateRequest
	) => Observable<MeTrainer>;
	updateCertificate: (
		certificateImage: Maybe<Blob>,
		certificate: UpdateTrainerCertificateRequest
	) => Observable<MeTrainer>;
	deleteCertificate: (certificateId: string) => Observable<MeTrainer>;
	downloadCertificate: (certificateId: string) => Observable<File>;

	// ------------------ OFFER TEMPLATE ------------------
	upsertOfferTemplate: (request: UpsertOfferTemplateRequest) => Observable<MeTrainer>;
	addPackageOfferVariants: (request: AddPackageOfferVariantsRequest) => Observable<MeTrainer>;
	updatePackageOfferVariants: (
		request: UpdatePackageOfferVariantsRequest
	) => Observable<MeTrainer>;
	deletePackageOfferVariants: (
		category: TrainingCategory,
		discipline: Discipline
	) => Observable<MeTrainer>;

	// ------------------ CASHOUTS ------------------
	addCashout: (currency: CurrencyCode) => Observable<void>;

	// ------------------ CALENDAR OFFERS ------------------
	getCalendarOffers: (query: CalendarOfferQuery) => Observable<CalendarOffer[]>;

	// ------------------ ON_BOARDING ------------------
	getOnBoardingUrl: (
		refreshUrl: string,
		returnUrl: string,
		type?: 'CREATE' | 'UPDATE'
	) => Observable<string>;

	// ------------------ BANK ACCOUNT ------------------
	getBalance: () => Observable<TrainerBalance>;
	getBankAccount: () => Observable<TrainerBankAccount>;
};

export const useSelfTrainerRepository = (): SelfTrainerFunctions => {
	const { addElements: addCalendarOffersToStore, getState: getCalendarOffersFromStore } =
		useStore<CalendarOffer>('calendar-offers');
	const { replaceElement: replaceTrainerInStore, getElement: getTrainerFromStore } =
		useStore<MeTrainer>('me-trainer');
	const { getElement: getClientFromStore, replaceElement: replaceClientInStore } =
		useStore<MeClient>('me-client');
	const { addElements: addOrdersToState } = useStore<Order>('orders');
	const { user } = useAuth0();

	const deleteMeCallback = useCallback((): Observable<void> => {
		return from(deleteMe()).pipe(
			handleApiError(),
			mapApiResult<DeleteMeResponse, void>((response) => response)
		);
	}, []);

	const getMeCallback = useCallback((): Observable<MeTrainer> => {
		if (isNil(user) || isNil(user.sub)) {
			return EMPTY;
		}
		return getTrainerFromStore(user.sub).pipe(
			switchMap((trainer) => {
				if (isNil(trainer)) {
					return from(getMe()).pipe(
						handleApiError(),
						mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
						tap((me) => replaceTrainerInStore(me.id, me))
					);
				}
				return of(trainer);
			})
		);
	}, [getTrainerFromStore, replaceTrainerInStore, user]);

	const getNextTrainingCallback = useCallback((): Observable<Maybe<TrainerTraining>> => {
		return from(getMyNextTraining()).pipe(
			map((response) => {
				if (response.response.status === 404) {
					return null;
				}
				return mapTrainerTraining(response.data!);
			})
		);
	}, []);

	const addStudyCallback = useCallback(
		(request: UpsertTrainerStudyRequest): Observable<MeTrainer> => {
			return from(addStudy({ body: request })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);
	const updateStudyCallback = useCallback(
		(studyId: string, request: UpsertTrainerStudyRequest): Observable<MeTrainer> => {
			return from(updateStudy({ path: { studyId: studyId }, body: request })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);
	const deleteStudyCallback = useCallback(
		(id: string): Observable<MeTrainer> => {
			return from(deleteStudy({ path: { studyId: id } })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const addAchievementCallback = useCallback(
		(request: UpsertTrainerAchievementRequest): Observable<MeTrainer> => {
			return from(addAchievement({ body: request })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);
	const updateAchievementCallback = useCallback(
		(
			achievementId: string,
			request: UpsertTrainerAchievementRequest
		): Observable<MeTrainer> => {
			return from(
				updateAchievement({
					path: { achievementId: achievementId },
					body: request
				})
			).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);
	const deleteAchievementCallback = useCallback(
		(id: string): Observable<MeTrainer> => {
			return from(deleteAchievement({ path: { achievementId: id } })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const addCertificateCallback = useCallback(
		(
			certificateImage: Blob | File,
			certificate: AddTrainerCertificateRequest
		): Observable<MeTrainer> => {
			return from(
				addCertificate({
					body: {
						certificateImage: certificateImage,
						certificate: new Blob([JSON.stringify(certificate)], {
							type: 'application/json'
						})
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);
	const updateCertificateCallback = useCallback(
		(
			certificateImage: Maybe<Blob>,
			certificate: UpdateTrainerCertificateRequest
		): Observable<MeTrainer> => {
			return from(
				updateCertificate({
					path: { certificateId: certificate.certificateId },
					body: {
						certificate: new Blob([JSON.stringify(certificate)], {
							type: 'application/json'
						}),
						...(isNil(certificateImage) ? {} : { certificateImage })
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);
	const deleteCertificateCallback = useCallback(
		(id: string): Observable<MeTrainer> => {
			return from(
				deleteCertificate({
					path: { certificateId: id }
				})
			).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const downloadCertificateCallback = useCallback((certificateId: string): Observable<File> => {
		return from(
			downloadSelfCertificate({
				parseAs: 'auto',
				path: { certificateId: certificateId }
			})
		).pipe(
			handleApiError(),
			castRequestResult<DownloadSelfCertificateResponse>(),
			map((r) => {
				const contentDisposition = r.response.headers.get('content-disposition');
				const fileName = (contentDisposition?.match(/filename="(.+?)"/) || [])[1] || '';
				return {
					...r,
					data: {
						data: r.data,
						fileName: fileName
					}
				};
			}),
			mapApiResult<DownloadFileResponse, File>((data: DownloadFileResponse) => {
				return new File([data.data], data.fileName);
			})
		);
	}, []);

	const upsertLocationCallback = useCallback(
		(request: UpsertTrainerLocationRequest) => {
			return from(upsertLocation({ body: request })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const deleteLocationCallback = useCallback(
		(request: DeleteTrainerLocationRequest) => {
			return from(deleteLocation({ body: request })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);
	const updateMeCallback = useCallback(
		(
			avatarImage: Maybe<Blob>,
			data: UpdateTrainerBasicInformationRequest
		): Observable<MeTrainer> => {
			if (isNil(user) || isNil(user.sub)) {
				return EMPTY;
			}
			return from(
				updateMe({
					body: {
						data: new Blob([JSON.stringify(data)], {
							type: 'application/json'
						}),
						...(isNil(avatarImage) ? {} : { avatarImage })
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer)),
				combineLatestWith(getClientFromStore(user?.sub).pipe(isNotNil(), take(1))),
				tap(([trainer, client]) => {
					replaceClientInStore(trainer.id, {
						...client,
						id: client.id,
						avatarUrl: trainer.avatarUrl,
						contactDetails: {
							...client.contactDetails,
							name: trainer.contactDetails.name,
							surname: trainer.contactDetails.surname,
							email: trainer.contactDetails.email,
							phone: trainer.contactDetails.phone
						}
					});
				}),
				map(([trainer, _]) => trainer)
			);
		},
		[getClientFromStore, replaceClientInStore, replaceTrainerInStore, user]
	);
	const updateNotificationCallback = useCallback(
		(enabledNotifications: Array<NotificationType>): Observable<MeTrainer> => {
			const requestBody: UpdateTrainerNotificationSettingsRequest = {
				enabledNotifications:
					enabledNotifications as UpdateTrainerNotificationSettingsRequest['enabledNotifications']
			};
			return from(updateNotifications({ body: requestBody })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const upsertOfferTemplateCallback = useCallback(
		(request: UpsertOfferTemplateRequest): Observable<MeTrainer> => {
			return from(upsertOfferTemplate({ body: request })).pipe(
				handleApiError(),
				mapApiResult<GetMeResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const addPackageOfferVariantsCallback = useCallback(
		(request: AddPackageOfferVariantsRequest): Observable<MeTrainer> => {
			return from(addPackageOfferVariants({ body: request })).pipe(
				handleApiError(),
				mapApiResult<MeTrainerResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const updatePackageTrainingVariantsCallback = useCallback(
		(request: UpdatePackageOfferVariantsRequest): Observable<MeTrainer> => {
			return from(updatePackageTrainingVariants({ body: request })).pipe(
				handleApiError(),
				mapApiResult<MeTrainerResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const deletePackageOfferVariantsCallback = useCallback(
		(category: TrainingCategory, discipline: Discipline) => {
			return from(
				deletePackageOfferVariants({ query: { category: category.name, discipline } })
			).pipe(
				handleApiError(),
				mapApiResult<MeTrainerResponse, MeTrainer>(mapMeTrainer),
				tap((trainer) => replaceTrainerInStore(trainer.id, trainer))
			);
		},
		[replaceTrainerInStore]
	);

	const getTrainerOrdersCallback = useCallback(
		(query: OrderQuery): Observable<Page<Order>> => {
			return from(
				listOrders({
					query: {
						startTime: isNil(query.startTime)
							? undefined
							: query.startTime?.toISOString(),
						endTime: isNil(query.endTime) ? undefined : query.endTime?.toISOString(),
						discipline: isNil(query.discipline) ? undefined : query.discipline,
						client: isNil(query.client) ? undefined : query.client,
						transactionTypes: query.transactionTypes || undefined,
						pageNumber: isNil(query.pageNumber) ? undefined : query.pageNumber,
						pageSize: isNil(query.pageSize) ? undefined : query.pageSize
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<ListOrdersResponse, Page<Order>>((response) => {
					const orders = response.content?.map((order) => mapOrder(order)) || [];
					return {
						content: orders,
						pageNumber: response.pageNumber,
						pageSize: response.pageSize,
						totalElements: response.totalElements,
						totalPages: response.totalPages,
						hasMore: response.hasMore
					};
				}),
				tap((orders) => addOrdersToState(orders.content))
			);
		},
		[addOrdersToState]
	);

	const getCalendarOffersCallback = useCallback(
		(query: CalendarOfferQuery): Observable<CalendarOffer[]> => {
			return getCalendarOffersFromStore().pipe(
				switchMap((calendarOffers) => {
					if (isNil(calendarOffers)) {
						return from(
							listCalendarOffers({
								query: {
									startTime: query.startTime?.toISOString() || undefined,
									endTime: query.endTime?.toISOString() || undefined
								}
							})
						).pipe(
							handleApiError(),
							mapApiResult<ListCalendarOffersResponse, CalendarOffer[]>(
								(listCalendarOffersResponse) =>
									listCalendarOffersResponse.map((lco) => mapCalendarOffer(lco))
							),
							tap(addCalendarOffersToStore)
						);
					}
					return of(filterCalendarOffers(calendarOffers, query));
				})
			);
		},
		[addCalendarOffersToStore, getCalendarOffersFromStore]
	);

	const getOnBoardingUrlCallback = useCallback(
		(
			refreshUrl: string,
			returnUrl: string,
			type: 'CREATE' | 'UPDATE' = 'UPDATE'
		): Observable<string> => {
			return from(
				getOnBoardingUrl({
					query: {
						refreshUrl,
						returnUrl,
						type
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<UrlDTO, string>((response) => response.url)
			);
		},
		[]
	);

	const addCashoutCallback = useCallback(
		(currency: CurrencyCode) =>
			from(
				addCashout({
					body: {
						currencyCode: currency
					}
				})
			).pipe(
				handleApiError(),
				mapApiResult<AddCashoutResponse, void>((response) => response)
			),
		[]
	);

	const getBalanceCallback = useCallback(() => {
		return from(getBalance()).pipe(
			handleApiError(),
			mapApiResult<TrainerBalanceResponse, TrainerBalance>(mapBalance)
		);
	}, []);

	const getBankAccountCallback = useCallback((): Observable<TrainerBankAccount> => {
		return from(getBankAccount()).pipe(
			handleApiError(),
			mapApiResult<TrainerBankAccountResponse, TrainerBankAccount>((response) => {
				return {
					id: response.id,
					balance: mapBalance(response.balance),
					accounts: Object.entries(response.accounts).map(([key, value]) => ({
						currency: key as CurrencyCode,
						name: value.name,
						last4IbanDigits: value.last4IbanDigits
					}))
				};
			})
		);
	}, []);

	return {
		addCashout: addCashoutCallback,
		updateNotifications: updateNotificationCallback,
		updateMe: updateMeCallback,
		addStudy: addStudyCallback,
		updateStudy: updateStudyCallback,
		deleteStudy: deleteStudyCallback,
		addAchievement: addAchievementCallback,
		updateAchievement: updateAchievementCallback,
		deleteAchievement: deleteAchievementCallback,
		addCertificate: addCertificateCallback,
		updateCertificate: updateCertificateCallback,
		deleteCertificate: deleteCertificateCallback,
		downloadCertificate: downloadCertificateCallback,
		deleteMe: deleteMeCallback,
		getMe: getMeCallback,
		getTrainerOrders: getTrainerOrdersCallback,
		getNextTraining: getNextTrainingCallback,
		upsertLocation: upsertLocationCallback,
		deleteLocation: deleteLocationCallback,
		upsertOfferTemplate: upsertOfferTemplateCallback,
		addPackageOfferVariants: addPackageOfferVariantsCallback,
		updatePackageOfferVariants: updatePackageTrainingVariantsCallback,
		deletePackageOfferVariants: deletePackageOfferVariantsCallback,
		getCalendarOffers: getCalendarOffersCallback,
		getOnBoardingUrl: getOnBoardingUrlCallback,
		getBalance: getBalanceCallback,
		getBankAccount: getBankAccountCallback
	};
};
